const ServiceScheduleForm = () => {
    return (
        <div className="container" id="servicescheduleform">
            <div className="row">
                <div style={{ marginTop: "10px" }} className="col-lg-12 col-md-12">
                    <div className="feature-box-5">
                        <h5 data-leng="PrimaryApplicant">Contact Info</h5>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group row">
                                <label
                                    data-leng="FirstName"
                                    htmlFor="firstname"
                                    className="col-md-5"
                                >
                  First Name *
                                </label>
                                <div className="col-md-7">
                                    <input type="text" className="form-control" id="firstname" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    data-leng="LastName"
                                    htmlFor="lastname"
                                    className="col-md-5"
                                >
                  Last Name *
                                </label>
                                <div className="col-md-7">
                                    <input type="text" className="form-control" id="lastname" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label
                                    className="control-label col-md-5"
                                    style={{ display: "inline-block" }}
                                >
                  Contact Preference?
                                </label>
                                <div className="col-md-7">
                                    <div className="radio radio-primary ax-radio">
                                        <input
                                            className="form-control"
                                            type="radio"
                                            name="ContactPreference"
                                            id="optradio-phone"
                                        />
                                        <label htmlFor="optradio-phone">
                                            <p>Phone</p>
                                        </label>
                                    </div>
                                    <div className="radio radio-primary ax-radio">
                                        <input
                                            className="form-control"
                                            type="radio"
                                            name="ContactPreference"
                                            id="optradio-email"
                                        />
                                        <label htmlFor="optradio-email">
                                            <p>Email</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group row">
                                <label
                                    data-leng="CellPhone"
                                    htmlFor="cellphone"
                                    className="col-md-5"
                                >
                  Cell Phone *
                                </label>
                                <div className="col-md-7">
                                    <input
                                        type="text"
                                        className="form-control isphone"
                                        id="cellphone"
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    data-leng="EmailAddress"
                                    htmlFor="emailaddress"
                                    className="col-md-5"
                                >
                  Email Address *
                                </label>
                                <div className="col-md-7">
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="emailaddress"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div style={{ marginTop: "10px" }} className="col-lg-12 col-md-12">
                    <div className="feature-box-5">
                        <h5 data-leng="PrimaryApplicant">Vehicle Info</h5>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group row">
                                <label
                                    data-leng="FirstName"
                                    htmlFor="year"
                                    className="col-md-5"
                                >
                  Year *
                                </label>
                                <div className="col-md-7">
                                    <input type="text" className="form-control" id="year" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    data-leng="CellPhone"
                                    htmlFor="make"
                                    className="col-md-5"
                                >
                  Make *
                                </label>
                                <div className="col-md-7">
                                    <input type="text" className="form-control" id="make" />
                                </div>
                            </div>

                            <div className="form-group row">
                                <label
                                    className="control-label col-md-5"
                                    style={{ display: "inline-block" }}
                                >
                  Under Warranty? *
                                </label>
                                <div className="col-md-7">
                                    <div className="radio radio-primary ax-radio">
                                        <input
                                            className="form-control"
                                            type="radio"
                                            name="UnderWarranty"
                                            aria-label="under warranty? yes"
                                            id="optradio-yes"
                                            data-keyvalue="Yes, still under warranty"
                                        />
                                        <label htmlFor="optradio-yes">
                      Yes, still under warranty
                                        </label>
                                    </div>

                                    <div className="radio radio-primary ax-radio">
                                        <input
                                            className="form-control"
                                            type="radio"
                                            name="UnderWarranty"
                                            aria-label="under warranty? no"
                                            data-keyname="UnderWarranty"
                                            id="optradio-no"
                                            data-keyvalue="No"
                                        />
                                        <label htmlFor="optradio-no">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group row">
                                <label
                                    data-leng="LastName"
                                    htmlFor="model"
                                    className="col-md-5"
                                >
                  Model *
                                </label>
                                <div className="col-md-7">
                                    <input type="text" className="form-control" id="model" />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label
                                    data-leng="EmailAddress"
                                    htmlFor="mileage"
                                    className="col-md-5"
                                >
                  Mileage *
                                </label>
                                <div className="col-md-7">
                                    <input type="text" className="form-control" id="mileage" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div style={{ marginTop: "10px" }} className="col-lg-12 col-md-12">
                    <div className="feature-box-5">
                        <h5 data-leng="PrimaryApplicant">Requested Service(s)</h5>
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group row">
                                <label className="col-md-12">Service(s) Needed: *</label>
                            </div>
                        </div>

                        {/* @for (int i = 0; i < services.Count; i++)
                {services.length
                    if (string.IsNullOrEmpty(services[i]))
                    {
                        continue;
                    }

                    string inputId = string.Format("service-{0}", i);
                    <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                            <div className="checkbox radio-primary">
                                <input className="form-control ax-requested-services" data-keyvalue="@(services[i])" data-keyname="RequestedServices" type="checkbox" id="@inputId" />
                                <label htmlFor="@inputId">
                                    @(services[i])
                                </label>
                            </div>
                        </div>
                    </div>
                } */}

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <div className="checkbox radio-primary">
                                    <input
                                        className="form-control"
                                        type="checkbox"
                                        id="service-other"
                                    />
                                    <label htmlFor="service-other">
                    Other (Please Describe Below)
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group row">
                                <label
                                    data-leng="LastName"
                                    htmlFor="descriptionservice"
                                    className="col-md-12"
                                >
                  Further Description / Request{" "}
                                </label>
                                <div className="col-md-12">
                                    <textarea
                                        className="form-control"
                                        id="descriptionservice"
                                        rows={8}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="form-group row">
                                <label
                                    data-leng="DateOfBirth"
                                    htmlFor="preferreddate"
                                    className="col-md-5"
                                >
                  Preferred Date
                                </label>
                                <div className="col-md-7">
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="preferreddate"
                                        data-date-format="MM/DD/YYYY"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="form-group row">
                                <label className="col-md-5" htmlFor="timeselector">
                  Time *
                                </label>
                                <div className="col-md-7">
                                    <select
                                        data-keyname="Time"
                                        id="timeselector"
                                        aria-label="select time"
                                        autoComplete="off"
                                        className="form-control requiredOptional"
                                    >
                                        <option value="9:00 am" selected>
                      9:00 am
                                        </option>
                                        <option value="9:30 am">9:30 am</option>
                                        <option value="10:00 am">10:00 am</option>
                                        <option value="10:30 am">10:30 am</option>
                                        <option value="11:00 am">11:00 am</option>
                                        <option value="11:30 am">11:30 am</option>
                                        <option value="12:00 pm">12:00 pm</option>
                                        <option value="12:30 pm">12:30 pm</option>
                                        <option value="1:00 pm">1:00 pm</option>
                                        <option value="1:30 pm">1:30 pm</option>
                                        <option value="2:00 pm">2:00 pm</option>
                                        <option value="2:30 pm">2:30 pm</option>
                                        <option value="3:00 pm">3:00 pm</option>
                                        <option value="3:30 pm">3:30 pm</option>
                                        <option value="4:00 pm">4:00 pm</option>
                                        <option value="4:30 pm">4:30 pm</option>
                                        <option value="5:00 pm">5:00 pm</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div style={{ marginTop: "10px" }} className="col-lg-12 col-md-12">
                    <div className="feature-box-5">
                        <div className="col-lg-12 col-md-12">
                            <div className="form-group row">
                                <div
                                    style={{ fontSize: "11px", paddingLeft: "15px" }}
                                    className="form-group"
                                >
                                    <span>* Mandatory fields.</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group row col-md-12">
                                {/* @Html.Raw(disclaimerText) */}
                            </div>
                        </div>

                        <div
                            className="col-lg-12 form-group"
                            style={{ paddingTop: "20px" }}
                        >
                            <a
                                className="button red"
                                href="#"
                                id="sendserviceschedule"
                            >
                Submit
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceScheduleForm;
