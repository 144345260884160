import RegexUtils from 'utils/RegexUtils';
import { object, string } from 'yup';

const { phoneRegex } = RegexUtils;

const ContactSchema = object().shape(
    { name: string().required().max(140),
        email: string().email().when('phone', {
            is: (phone: string) => !phone || phone.length === 0,
            then: string().email().required(),
            otherwise: string()
        }),
        phone: string().when('email', {
            is: (email: string) => !email || email.length === 0,
            then: string().required().matches(phoneRegex, 'cell phone is not valid, please use this format 999-999-9999'),
            otherwise: string()
        }),
        message: string().required(),
    }, [ [ 'email', 'phone' ] ]);

export default ContactSchema;
