import { useEffect, useState } from 'react';
import Link from 'next/link';
import { renderToString } from 'react-dom/server';

const VehicleMakeComponent = ({ makes }: any) => {
    return (
        <div className="col-lg-12 col-md-12">
            {makes && makes.length > 0 && makes.map((item: any, index: number) => {
                const make = item.make.replace('&', 'AND');
                return (
                    <div key={index} className='col-md-3 col-xs-6'>
                        <div className="text-left font-size-mobile">
                            <Link className="text-red" href={`vehicles?make=${make}`} prefetch={false}>
                                {make}<span className='p-l-8'>({item.value})</span>
                            </Link>
                        </div>
                    </div>
                );
            })
            }
        </div>
    );
};

const VehicleMakes = () => {
    const [data, setData] = useState(null);

    const getMakes = async() => {
        const result = await fetch(`/api/vehicles/makesgroup`).then((response) => response.json());
        setData(result.list);
    };

    useEffect(() => {
        getMakes();
    },[]);

    return renderToString(<VehicleMakeComponent makes={data} />);
};

export { 
    VehicleMakes,
    VehicleMakeComponent,
};