import Captcha from "components/Captcha/Captcha";
import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import { isEmpty } from 'utils/commonUtils';
import { useState } from "react";
import useMailMethods from "components/Hooks/useMailMethods";
import Router from "next/router";

const initialState = {
    message: '',
    captchaVerified: false,
};

const SuggestionBoxForm = ({ settings }: any) => {
    const [record, setRecord] = useState(initialState);
    const [submitError, setSubmitError] = useState('');
    const [isSaving, setSaving] = useState(false);
    const { sendEmail } = useMailMethods();
    const { generalInfo, companyInfo } = settings;

    const onChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setRecord((item) => ({
            ...item,
            [name]: value,
        }));
    };

    const onValidateCaptcha = async (val: boolean) => {
        setRecord({
            ...record,
            captchaVerified: val,
        });
    };

    const onSubmit = async () => {
        if (isEmpty(record.message)) return;
        setSaving(true);
        setSubmitError('');

        try {
            const result = await sendEmail({
                from: companyInfo.companyName, 
                to: generalInfo.suggestionEmail, 
                subject: 'Suggestion Message', 
                isHtml: true,
                message: `<strong>User: </strong><span>Anonymous user</span><br/><strong>Suggestion: </strong><br/> <span>${record.message}</span>`
            });
            
            setSaving(false);

            if (result) {
                Router.push("/suggestion/thankyou");
            }else {
                setSubmitError('error while send email');
            }

        } catch (error: any) {
            setSaving(false);
            setSubmitError(error?.message || error);
        }
    };

    return (
        <>
            <div className="form-horizontal" id="suggestionBoxform">
                <div className="contact-form">
                    <div className="col-lg-12">
                        <i
                            style={{ fontSize: "50px", color: "#6dbf6a", padding: "20px" }}
                            className="fa fa-commenting-o"
                            aria-hidden="true"
                        ></i>
                        <p>Share your thoughts and ideas with us, we want your feedback!</p>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <label className="text-left" htmlFor="user-message">
                My feedback or suggestion *
                            </label>
                            <textarea
                                className="form-control input-message"
                                placeholder="write your message here"
                                rows={7}
                                onChange={onChange}
                                name="message"
                                disabled={isSaving}
                            ></textarea>
                            {isEmpty(record.message) && (<div><ErrorMessage message="Please fill all the required fields!." /></div>)}
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <Captcha
                            onValidateCaptcha={(val: boolean)=> onValidateCaptcha(val)}
                        />
                    </div>
                    <div className="col-lg-12 col-md-12">
                        {!record.captchaVerified && (<div><ErrorMessage message="Please solve the captcha." /></div>)}
                        <button disabled={isSaving || isEmpty(record.message) || !record.captchaVerified} onClick={onSubmit} className="button red m-t-20">
                            {isSaving ? <Loading text='processing...' /> : 'Send your message'}
                        </button>
                        {submitError.length > 0 && (<div><ErrorMessage message={submitError} /></div>)}
                    </div>
                </div>
            </div>

            <div
                style={{ display: "none", padding: "40px" }}
                className="col-lg-12 thk-message"
            >
                <h3>Thank You!</h3>
                <i
                    style={{ fontSize: "50px", color: "#6dbf6a" }}
                    className="fa fa-check"
                    id="checkmark"
                ></i>
                <p>
          Thanks a bunch for your feedback. It means a lot to us! We really
          appreciate you giving us a moment of your time today.
                </p>
            </div>
        </>
    );
};

export default SuggestionBoxForm;
