import { isEmpty } from 'utils/commonUtils';

const LotInformationContact = ({
    pageSettings,
}: {pageSettings: any}) => {

    const { companyInfo, lotSettings } = pageSettings;

    const { companyCode, companyName, companyCity, companyAddress, companyState, companyZip } = companyInfo;
    const lotList = lotSettings.sort((a: any, b: any)=> a.sort.localeCompare(b.sort));
    const manyLots = lotList?.length > 1;

    const contactEmail: any = "";

    const urlMap = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCoz-TkaOt1QRQ7iZoDoGE6cq70ayiGlIA&q=${companyName}+${companyCity}+${companyAddress}+${companyCity}+${companyState}+${companyZip}`;

    const itemSum = lotList.length;
    let itemCol = 2;

    switch (itemSum) {
    case 1:
        itemCol = 12;
        break;
    case 2:
        itemCol = 6;
        break;
    case 3:
        itemCol = 4;
        break;
    case 4:
        itemCol = 3;
        break;
    case 5:
        itemCol = 4;
        break;
    case 6:
        itemCol = 4;
        break;
    case 7:
        itemCol = 3;
        break;
    case 8:
        itemCol = 3;
        break;
    case 9:
        itemCol = 4;
        break;
    case 10:
        itemCol = 3;
        break;
    case 11:
        itemCol = 3;
        break;
    case 12:
        itemCol = 3;
        break;
    default:
        itemCol = 2;
        break;
    }

    return (
        <>
            {manyLots && (
                <div className="row">
                    {lotList.map((item: any, index: number) => (
                        <div
                            key={index}
                            className={`col-lg-${itemCol} col-md-${itemCol} col-sm-${itemCol}`}
                        >
                            <div className="contact-box text-center">
                                <div className="ax-contact-map">
                                    <iframe
                                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCoz-TkaOt1QRQ7iZoDoGE6cq70ayiGlIA&q=${item.lotAddress}+${item.lotZip}`}
                                        allowFullScreen
                                    ></iframe>
                                </div>
                                <h5 className="lotOverrideName">{item.lotName}</h5>
                                <div className="ax-lot-info">
                                    <ul>
                                        <li>
                                            <a
                                                className="text-red"
                                                target="_blank"
                                                href={`https://www.google.com/maps/search/?api=1&query=+${item.lotAddress}`}
                                                rel="noreferrer"
                                            >
                                                <i className="fa fa-map-marker"></i>
                                                <span style={{ textTransform: "uppercase" }}>
                                                    {item.lotAddress}
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="text-red"
                                                href={`tel:${item.lotPhone}`}
                                            >
                                                <i className="fa fa-phone"></i>
                                                <span>{item.lotPhone}</span>
                                            </a>
                                        </li>

                                        {/* 
                            @try
                            {
                                string address = crmLotsInfo != null ? crmLotsInfo[item.LotId.ToString()].ToString() : "";
                                if (address != "")
                                {
                                    <li> <a style={{color: '#909090'}} href="mailto:@address"><i className="fa fa-envelope-o"></i><span>@address</span> </a></li>
                                }
                            }
                            catch (Exception)
                            {

                            } */}

                                        {companyCode === 160 && (
                                            <li className="hidden-lg hidden-md">
                                                <i className="fa fa-clock-o" aria-hidden="true">
                          &nbsp;
                                                </i>
                                                <span style={{ fontSize: "11px" }}>
                          Mon - Thur 10:00 - 7:00 | Fri 10:00 - 8:00 | Sat 9:00
                          - 5:00 | Sunday CLOSED.&nbsp;
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!manyLots && (
                <>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            {lotList.length > 0 && (
                                <div className="contact-box text-center">
                                    <i className="fa fa-map-marker"></i>
                                    <h5>Address</h5>
                                    {lotList?.map((item: any, index: number) => (
                                        <a
                                            key={index}
                                            style={{ color: "#99999" }}
                                            target="_blank"
                                            href={`https://www.google.com/maps/search/?api=1&query=${companyName}+${item.lotCity}+${item.lotAddress}+${item.lotCity}+${item.lotState}+${item.lotZip}`}
                                            rel="noreferrer"
                                        >
                                            <p>
                                                `${item.lotAddress} ${item.lotCity} {item.lotState} ${item.lotZip}`
                                            </p>
                                        </a>
                                    ))}
                                </div>
                            )}

                            {lotList.length === 0 && (
                                <a
                                    className="ax-contact-link"
                                    target="_blank"
                                    href={`https://www.google.com/maps/search/?api=1&query=${companyName}+${companyCity}+${companyAddress}+${companyCity}+${companyState}+${companyZip}`}
                                    rel="noreferrer"
                                >
                                    <div className="contact-box text-center">
                                        <i className="fa fa-map-marker"></i>
                                        <h5>Address</h5>
                                        <p>
                        `${companyAddress} ${" "}
                                            {companyCity} ${" "}
                                            {companyState} ${" "}
                                            {companyZip}`
                                        </p>
                                    </div>
                                </a>
                            )}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            {lotList.length > 0 && (
                                <div className="contact-box text-center">
                                    <i className="fa fa-phone"></i>
                                    <h5>Phone</h5>
                                    {lotList.map((item: any, index: number) => (
                                        <a
                                            style={{ color: "#99999" }}
                                            href={`tel:${item.lotPhone}`}
                                            key={index}
                                        >
                                            <p>{item.lotPhone}</p>
                                        </a>
                                    ))}
                                </div>
                            )}
                            {lotList.length === 0 && (
                                <a
                                    className="ax-contact-link"
                                    href={`tel:${companyCode}`}
                                >
                                    <div className="contact-box text-center">
                                        <i className="fa fa-phone"></i>
                                        <h5>Phone</h5>
                                        <p> {companyCode}</p>
                                    </div>
                                </a>
                            )}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <a className="ax-contact-link" href={`mailto:${contactEmail}`}>
                                <div className="contact-box text-center">
                                    <i className="fa fa-envelope-o"></i>
                                    <h5>Email</h5>
                                    {contactEmail && !isEmpty(contactEmail) &&
                                        contactEmail?.split(",").map((item: string, index: number) => (
                                            <p key={index}>{item}</p>
                                        ))}
                                </div>
                            </a>
                        </div>
                    </div>
                    <section className="contact-map" style={{paddingTop:'20px'}}>
                        <div className="container-fluid">
                            <iframe src={urlMap} allowFullScreen></iframe>
                        </div>
                    </section>
                </>
            )}
        </>
    );
};

export default LotInformationContact;
