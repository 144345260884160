import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const initState = [
    {
        id: 'axm-ada-keyb',
        name: 'Keyboard Nav',
        icon: 'fa fa-keyboard-o',
        active: false,
    },
    {
        id: 'axm-ada-hl',
        name: 'Hihglight Links',
        icon: 'fa fa-link',
        active: false,
    },
    {
        id: 'axm-ada-cur',
        name: 'Cursor',
        icon: 'fa fa-mouse-pointer',
        active: false,
    },
];

const AdaFeature = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const [options, setOptions] = useState(initState);

    const onCloseModal = () => {
        setOpenPopup(false);
    };

    const setBodyClass = (classEle: string, operation: boolean) => {
        const bodyElement = document.getElementsByTagName('body')[0] as HTMLElement;
        if(operation){
            bodyElement?.classList.add(classEle);
        } else {
            bodyElement?.classList.remove(classEle);
        }
    };

    const activeAdaOption = (item: any) => {
        const newItems = options.map((c)=> {
            const currentValue = String(c.id) === String(item.id) ? !c.active : c.active;
            localStorage.setItem(String(c.id), String(currentValue));
            setBodyClass(c.id, currentValue);
            return {
                ...c,
                active: currentValue,
            };
        });

        setOptions(newItems);
    };

    useEffect(() => {
        const newItems = options.map((item)=>{
            const currentLocalValue = localStorage.getItem(String(item.id));
            const value = Boolean(currentLocalValue === 'true') ??  false;
            setBodyClass(item.id, value);
            return {
                ...item,
                active: value,
            };
        });

        setOptions(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <React.Fragment>
            <span onClick={() => setOpenPopup(true)}>Enable Accessibility</span>
            <Modal className="ada-modal-feature"
                isOpen={openPopup}
                backdrop="static"
                toggle={onCloseModal}>
                <ModalHeader>                    
                    Accessibility Options
                    <button type="button" onClick={onCloseModal} className="btn-close" aria-label="Close">&times;</button>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        {options.map((item)=> <div key={item.id} className="col-md-4 col-xs-12">
                            <button onClick={()=> activeAdaOption(item)} 
                                type="button" 
                                className={`selectorcomponent btn btn-default ${item.active ? 'selected' : ''}`}
                                id={item.id}>
                                <i className={item.icon}></i> 
                                {item.name}
                            </button>
                        </div>)}
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default AdaFeature;