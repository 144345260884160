import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { addParamToVehicleUrl, numberWithCommasAndSymbol, numberWithCommas } from "utils/commonUtils";
import dynamic from "next/dynamic";
import useResize from "components/Hooks/useResize";
import { DeviceEnum } from "models/enums";
import { VehicleContext } from "context/vehicleContext";
import { FiltersDB } from "models/filtersDB";
import { getFiltersInArray } from "utils/arrayUtils";

const SearchInput = dynamic(
    () => import("components/vehicles/SRP/SearchInput")
);

const showOptions = [
    {
        label: "20",
        value: "20",
        arialabel: "Show 20",
    },
    {
        label: "50",
        value: "50",
        arialabel: "Show 50",
    },
    {
        label: "75",
        value: "75",
        arialabel: "Show 75",
    },
    {
        label: "100",
        value: "100",
        arialabel: "Show 100",
    },
];

const SearchBar = ({ companyInfo, srpSettings }: any) => {
    const router = useRouter();
    const { whichDevice } = useResize();
    const { resetFiltersClearAll, setSelectedItemInDropdown,
        setSelectedItemInSlider, setStateValues,
        currentState, filtersFromUrl, vehicleResponse, paramsFromUrl 
    } = useContext(VehicleContext);

    const { viewType, filtersData } = currentState;

    const [openMoreFilters, setOpenMoreFilters] = useState(false);
    const showMiles = srpSettings.showMiles;
    const showPrice = srpSettings.showPrice;
    const showGridListOption = srpSettings.showGridListOption && srpSettings.srpLayout !== "cardViewLayout";
    const lockedSearchAtTop = Boolean(srpSettings.lockedSearchAtTop);
    const isMobileDevice = whichDevice === DeviceEnum.MOBILE;

    const sortOptions = [
        {
            label: "Recommended",
            value: "default",
            arialabel: "",
            enable: true,
        },
        {
            label: "Newest Stock Number",
            value: "stocknumber|desc",
            arialabel: "sort by the newest car",
            enable: companyInfo.companyCode !== 1,
        },
        {
            label: "Oldest Stock Number",
            value: "stocknumber|asc",
            arialabel: "sort by the oldest car",
            enable: companyInfo.companyCode !== 1,
        },
        {
            label: "Make A-Z",
            value: "make|asc",
            arialabel: "sort by make ascendent",
            enable: true,
        },
        {
            label: "Make Z-A",
            value: "make|desc",
            arialabel: "sort by make descendent",
            enable: true,
        },
        {
            label: "Newest Year",
            value: "year|desc",
            arialabel: "sort by the newest year",
            enable: true,
        },
        {
            label: "Oldest Year",
            value: "year|asc",
            arialabel: "sort by the oldest year",
            enable: true,
        },
        {
            label: "Lowest Mileage",
            value: "miles|asc",
            arialabel: "sort by the lowest miles",
            enable: showMiles,
        },
        {
            label: "Highest Mileage",
            value: "miles|desc",
            arialabel: "sort by the highest miles",
            enable: showMiles,
        },
        {
            label: "Lowest Price",
            value: "internetprice|asc",
            arialabel: "sort by the lowest price",
            enable: showPrice,
        },
        {
            label: "Highest Price",
            value: "internetprice|desc",
            arialabel: "sort by the higher price",
            enable: showPrice,
        },
    ];

    const onViewSelected = (val: string) => {
        router.push(addParamToVehicleUrl("tv", val));
        setStateValues({
            viewType: val,
        });
    };

    const setOrderBy = (val: string) => {
        router.push(addParamToVehicleUrl("sort", val));
        setStateValues({
            sortBy: val,
        });
    };

    const setShowQuantity = (val: string) => {
        router.push(addParamToVehicleUrl("show", val));
        setStateValues({
            showQuantity: val,
        });
    };

    const onClearItem = (item: any) => {
        const { filterType, filterName, value } = item;

        if(filterType === 'array')
        {
            setSelectedItemInDropdown(filterName, { keyName: value, isSelected: true } );
        }
        else if (filterType === 'range') {
            const data = filtersData?.find((c: FiltersDB) => c.typeName.toLowerCase() === filterName.toLowerCase());

            const ranges = data?.keyName?.split("|") ?? [0, 0];

            const minSelected = Number((ranges?.[0] < 0 ? 0 : ranges?.[0]) ?? 0);
            const maxSelected = Number((ranges?.[1] < 0 ? 0 : ranges?.[1]) ?? 0);

            if (filterName === 'miles') setSelectedItemInSlider(filterName, ['0', String(maxSelected)], data, true);
            else setSelectedItemInSlider(filterName, [String(minSelected), String(maxSelected)], data, true);
        }
    };

    const filtersApplied: any = [];
    const filtersFromUI = getFiltersInArray(filtersFromUrl);

    filtersFromUI
        .filter((c: any) => c.isFiltered)
        .sort((a: any, b: any) => a.orderFiltered - b.orderFiltered)
        .forEach((item: any) => {
            if (item.filterType === "range") {
                filtersApplied.push({
                    filterName: item.filterName,
                    label: item.label,
                    filterType: item.filterType,
                    value: item.values,
                });
            } else {
                item.values.forEach((value: any) => {
                    filtersApplied.push({
                        filterName: item.filterName,
                        label: item.label,
                        filterType: item.filterType,
                        value: value,
                    });
                });
            }
        });

    const totalItems = vehicleResponse.totalSearch;

    return (
        <div
            className={`col-lg-12 sorting-options-main ${lockedSearchAtTop && whichDevice === DeviceEnum.MOBILE ? "" : "ax-fixed"
            }`}
        >
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-8 col-xs-12 ax-search-bar">
                    <SearchInput
                        filtersApplied={filtersApplied}
                        openMoreFilters={openMoreFilters}
                        setOpenMoreFilters={setOpenMoreFilters}
                        onClearItem={onClearItem}
                    />
                </div>
                {showGridListOption && (
                    <div className="col-lg-4 col-md-4 col-xs-4 sorting-options ">
                        <div className="change-view-button">
                            <span
                                className={`viewgrid ${viewType === "grid" ? "active" : ""
                                }`}
                                aria-label="select"
                                onClick={() => onViewSelected("grid")}
                                role="button"
                            >
                                {" "}
                                <i className="fa fa-th"></i>{" "}
                            </span>
                            <span
                                className={`viewlist ${viewType === "list" ? "active" : ""
                                }`}
                                aria-label="select"
                                onClick={() => onViewSelected("list")}
                                role="button"
                            >
                                {" "}
                                <i className="fa fa-list-ul"></i>{" "}
                            </span>
                        </div>
                    </div>
                )}
            </div>

            <div className="row">
                {isMobileDevice && (
                    <div className="col-lg-9 col-md-7 col-xs-12 p-t-10 total-filters hidden-lg hidden-md">
                        {totalItems > 0 && (
                            <span
                                className="messageinfofiltered"
                                style={{ fontWeight: "bold" }}
                            > Found {totalItems} vehicles
                            </span>
                        )}
                    </div>
                )}
                {!isMobileDevice && (
                    <div className="col-lg-9 col-md-6 col-xs-7 p-t-10 filters-lg hidden-sm hidden-xs">
                        {totalItems > 0 && (
                            <span
                                className="messageinfofiltered"
                                style={{ fontWeight: "bold" }}
                            >
                Found {totalItems} vehicles
                            </span>
                        )}
                        {filtersApplied.slice(0, 5).map((item: any) => {
                            let label = '';
                            if (item.filterType === 'range' && item.filterName === 'internetPrice') {
                                label = item.label + ' ' + item.value.map((c: string) => numberWithCommasAndSymbol(Number(c))).join(' - ');
                            } else if (item.filterType === 'range' && item.filterName === 'miles') {
                                label = item.label + ' <= ' + item.value.map((c: string) => numberWithCommas(Number(c)))?.[1];
                            } else if (item.filterType === 'range' && item.filterName === 'year') {
                                label = item.label + ' ' + item.value.map((c: string) => Number(c)).join(' - ');
                            } else {
                                label = item.value;
                            }

                            return (
                                <div
                                    className="breadcrumb-btn btn m-btn"
                                    key={`${item.filterName}_${item.value}`}
                                    onClick={()=> onClearItem(item)}
                                >
                                    {label}
                                    <span className="fa fa-close"></span>
                                </div>
                            );
                        })}
                        {filtersApplied.length <= 5 && filtersApplied.length > 0 && (
                            <div
                                className="breadcrumb-btn btn m-btn"
                                onClick={() => resetFiltersClearAll()}
                            >
                Clear All<span className="fa fa-close"></span>
                            </div>
                        )}
                        {filtersApplied.length > 5 && (
                            <div className="breadcrumb-btn btn m-btn ax-show-more">
                                {!openMoreFilters && (
                                    <span onClick={() => setOpenMoreFilters(!openMoreFilters)}>
                    +{filtersApplied.length - 5} MORE
                                    </span>
                                )}
                                {openMoreFilters && (
                                    <span onClick={() => setOpenMoreFilters(!openMoreFilters)}>
                    HIDE
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className="selected-box show-item col-lg-1 col-md-2 col-xs-5 p-t-10 text-center">
                    <span className="bold">Show</span>
                    <select
                        className="select-box-text text-right"
                        onChange={(e) => setShowQuantity(e.target.value)}
                        value={paramsFromUrl.show}
                    >
                        {showOptions.map((item: any) => {
                            return (
                                <option
                                    aria-label={item.arialabel}
                                    value={item.value}
                                    key={item.value}
                                    className="text-left"
                                >
                                    {item.label}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="selected-box sort-by-item col-lg-2 col-md-4 col-xs-7 p-t-10 text-center">
                    <span className="bold p-r-20">Sort By</span>
                    <select
                        className="select-box-text text-right"
                        onChange={(e) => setOrderBy(e.target.value)}
                        value={paramsFromUrl.sort}
                    >
                        {sortOptions.filter(c=> c.enable).map((item: any) => {
                            return (
                                <option
                                    aria-label={item.arialabel}
                                    value={item.value}
                                    key={item.value}
                                    className="text-left"
                                >
                                    {item.label}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
