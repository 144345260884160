import { useState } from 'react';

const GetDirectionsForm = ({ settings }: { settings: any }) => {
    const { companyInfo = {} } = settings;
    const dbAddress = `${companyInfo.companyAddress} ${companyInfo.companyCity} ${companyInfo.companyState} ${companyInfo.companyZip}`;
    const websiteId = Number(process.env.WEBSITE_ID) === 0 ? 1 : Number(process.env.WEBSITE_ID);

    const [record, setRecord] = useState({ address: websiteId === 6 ? '21151 NW 2nd Ave, Miami,FL33169' : dbAddress, locType: 'miami' });

    const onChange = (address: string, locType: string) => {
        setRecord({ address, locType });
    };

    if (companyInfo.companyCode === 1) {
        if ((websiteId === 3 || websiteId === 5)) {
            return (
                <form
                    className="getdirections"
                    action="https://maps.google.com/maps"
                    method="get"
                    target="_blank"
                >
                    <input
                        type="text"
                        className="go-to-the-store placeholder text-black"
                        name="saddr"
                        placeholder="Enter Your Starting Address"
                        aria-label="enter your address to go to carvix"
                    />
                    <input
                        type="hidden"
                        name="daddr"
                        value="Carvix Used Cars For Sale San Antonio, 8762 Texas 151 Access Rd, San Antonio, TX 78245, USA"
                    />
                    <button type="submit" className="button">
                        GO
                    </button>
                </form>
            );
        } else {
            return (
                <form
                    className="getdirections"
                    action="https://maps.google.com/maps"
                    method="get"
                    target="_blank"
                >
                    <div className="form-check form-check-inline">
                        <input onClick={(e:any) => onChange(e.target.value, "miami")} defaultChecked={record.locType === 'miami'} className="form-check-input" type="radio" name="location-addr" id="inlineRadio1" value="21151 NW 2nd Ave, Miami, FL 33169, Florida Fine Cars" />
                        <label className="form-check-label" htmlFor="inlineRadio1">Miami</label>
                        <span />
                        <input onClick={(e:any) => onChange(e.target.value, "westpalm")} defaultChecked={record.locType === 'westpalm'} className="form-check-input" type="radio" name="location-addr" id="inlineRadio2" value="1220 North Military Trail, West Palm Beach, FL 33409, Florida Fine Cars" />
                        <label className="form-check-label" htmlFor="inlineRadio2">West Palm</label>
                        <span />
                        <input onClick={(e:any) => onChange(e.target.value, "margate")} defaultChecked={record.locType === 'margate'} className="form-check-input" type="radio" name="location-addr" id="inlineRadio3" value="5300 West Copans Road, Margate, FL 33063, Florida Fine Cars" />
                        <label className="form-check-label tex-white" htmlFor="inlineRadio3">Margate</label>
                        <span />
                        <input onClick={(e:any) => onChange(e.target.value, "orlando")} defaultChecked={record.locType === 'orlando'} className="form-check-input" type="radio" name="location-addr" id="inlineRadio4" value="5474 S Orange Blossom Trl, Orlando, FL 32839, Florida Fine Cars" />
                        <label className="form-check-label tex-white" htmlFor="inlineRadio4">Orlando</label>
                    </div>
                    <input
                        type="text"
                        className="go-to-the-store placeholder text-black"
                        name="saddr"
                        placeholder="Enter Your Starting Address"
                    />
                    <input type="hidden" name="daddr" value={record.address} />
                    <button type="submit" className="button">
                        GO
                    </button>
                </form>
            );
        }
    }
    
    return (
        <form
            className="getdirections"
            action="https://maps.google.com/maps"
            method="get"
            target="_blank"
        >
            <input
                type="text"
                className="go-to-the-store placeholder text-black"
                name="saddr"
                placeholder="Enter Your Starting Address"
            />
            <input type="hidden" name="daddr" value={record.address} />
            <button type="submit" className="button">
                GO
            </button>
        </form>
    );
};

export default GetDirectionsForm;