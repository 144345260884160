import AdaFeature from "components/ADAFeature/AdaFeature";
import IFrameComponent from "components/BlockCodes/IFrameComponent";
import { CreateSocialMediaLink } from "components/BlockCodes/SocialMediaLink";
import { TradePendingSearch } from "components/BlockCodes/TradePendingSearch";
import { VehicleMakes } from "components/BlockCodes/VehicleMakes";
import CareerForm from "components/Forms/CareerForm";
import ContactForm from "components/Forms/ContactForm";
import GetDirectionsForm from "components/Forms/GetDireccionForm";
import ServiceScheduleForm from "components/Forms/ServiceScheduleForm";
import SuggestionBoxForm from "components/Forms/SugestionBoxForm";
import LotInformationContact from "components/LotInformationContact/LotInformationContact";
import PaymentCalculatorForm from "components/PaymentCalculator/PaymentCalculatorForm";
import SearchBar from "components/vehicles/SRP/SearchBar";
import { AppContext } from "context/appContext";
import { useContext, useEffect } from "react";
import { hydrateRoot } from 'react-dom/client';
import { renderToString } from "react-dom/server";
import { cloudinaryApply } from "utils/cloudinaryUtils";
import { extractStyle, removeStyleTag, stringMinify } from "utils/StringUtils";

interface IHtmlProps {
    htmlValue: string,
    settings?: any,
    additionalValues?: any,
}

const HtmlComponent = ({ htmlValue, settings, additionalValues }: IHtmlProps) => {
    const {settings: pageSettings} = useContext(AppContext);
    let styleInline = null;
    let htmlProcessed = htmlValue;
    if (htmlValue?.includes('<style>')) {
        styleInline = cloudinaryApply(extractStyle(htmlValue));
        htmlProcessed = removeStyleTag(htmlValue);
    }

    useEffect(() => {
        const container = document.getElementById('contact-form-server');
        if (container && htmlValue.includes('[ContactForm]')) {
            hydrateRoot(container, <ContactForm settings={settings}/>);
        }

        const containerSuggestion = document.getElementById('suggestionboxid');
        if (containerSuggestion && htmlValue.includes('[SuggestionBoxForm]')) {
            hydrateRoot(containerSuggestion, <SuggestionBoxForm settings={settings}/>);
        }

        const adaContainer = document.getElementById('ada-container-id');
        if (adaContainer && htmlValue?.includes('[AdaFeatureLink]')) {
            hydrateRoot(adaContainer, <AdaFeature />);
        }

        const directionContainer = document.getElementById('directionform');
        if (directionContainer && htmlValue?.includes('[GetDirectionsForm]')) {
            hydrateRoot(directionContainer, <GetDirectionsForm settings={pageSettings} />);
        }

        const tradePendingContainer = document.getElementById('trade-in-container-id');
        if (tradePendingContainer && htmlValue?.includes('[TradePendingSearch]')) {
            hydrateRoot(tradePendingContainer, <TradePendingSearch />);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideBlocks = (newHtml: string) => {
        const blockcodes = [
            "[VehiclesCounter]", "[PaymentCalculator]", "[CompanyName]", "[FinancingPhone]", "[VehicleMakes]",
            "[VehiclesByStyle]", "[ContactForm]", "[LotsInformationContact]", "[CompanyMapLocation]", "[ServiceScheduleForm]",
            "[FacebookLink]", "[TwitterLink]", "[InstagramLink]", "[YoutubeLink]", "[CareerForm]", "[CareerSpecialForm]", "[GetDirectionsForm]", "[SearchBar]",
            "[SecureUploadDocument]", "[SuggestionBoxForm]", "[IframeCredit]","[AdaFeatureLink]","[TradePendingSearch]"
        ];

        blockcodes.forEach(item => {
            if (newHtml.includes(item)) {
                switch (item) {
                case '[VehiclesCounter]':
                    newHtml = newHtml.replace(item, '<div>Process VehiclesCounter</div>');
                    break;
                case '[PaymentCalculator]':
                    newHtml = newHtml.replace(item, renderToString(<PaymentCalculatorForm />));
                    break;
                case '[CompanyName]':
                    newHtml = newHtml.replace(item, pageSettings.companyInfo.companyName);
                    break;
                case '[VehicleMakes]':
                    newHtml = newHtml.replace(item, VehicleMakes);
                    break;
                case '[ContactForm]':
                    newHtml = newHtml.replace(item, renderToString(<div id='contact-form-server'><ContactForm settings={settings} /></div>));
                    break;
                case '[LotsInformationContact]':
                    newHtml = newHtml.replace(item,  renderToString(<LotInformationContact pageSettings={pageSettings}/>));
                    break;
                case '[CompanyMapLocation]':
                    newHtml = newHtml.replace(item, '<div>Process CompanyMapLocation</div>');
                    break;
                case '[ServiceScheduleForm]':
                    newHtml = newHtml.replace(item, renderToString(<ServiceScheduleForm />));
                    break;
                case '[CareerForm]':
                    newHtml = newHtml.replace(item, renderToString(<CareerForm />));
                    break;
                case '[CareerSpecialForm]':
                    newHtml = newHtml.replace(item, '<div>Process CareerSpecialForm</div>');
                    break;
                case '[FacebookLink]':
                    newHtml = newHtml.replace(item, CreateSocialMediaLink(item, "facebook", settings));
                    break;
                case '[TwitterLink]':
                    newHtml = newHtml.replace(item, CreateSocialMediaLink(item, "twitter", settings));
                    break;
                case '[InstagramLink]':
                    newHtml = newHtml.replace(item, CreateSocialMediaLink(item, "instagram", settings));
                    break;
                case '[YoutubeLink]':
                    newHtml = newHtml.replace(item, CreateSocialMediaLink(item, "youtube", settings));
                    break;
                case '[GetDirectionsForm]':
                    newHtml = newHtml.replace(item, renderToString(<div id='directionform'><GetDirectionsForm settings={pageSettings}/></div>));
                    break;
                case '[SearchBar]':
                    newHtml = newHtml.replace(item, renderToString(<SearchBar />));
                    break;
                case '[SecureUploadDocument]':
                    newHtml = newHtml.replace(item, '<div>Process SecureUploadDocument</div>');
                    break;
                case '[SuggestionBoxForm]':
                    newHtml = newHtml.replace(item, renderToString(<div id='suggestionboxid'><SuggestionBoxForm settings={settings} /></div>));
                    break;
                case '[IframeCredit]':
                    newHtml = newHtml.replace(item, additionalValues.formType !== 'automatrix' ? renderToString(<IFrameComponent iframeUrl={additionalValues.iframeUrl} />) :  '');
                    break;
                case '[AdaFeatureLink]':
                    newHtml = newHtml.replace(item, renderToString(<div id='ada-container-id'><AdaFeature /></div>));
                    break;
                case '[TradePendingSearch]':
                    newHtml = newHtml.replace(item, renderToString(<div id='trade-in-container-id'><TradePendingSearch /></div>));
                    break;
                default:
                    newHtml = newHtml.replace(item, '');
                    break;
                }
            }
        });

        return newHtml;
    };

    const regexToGetAllInsideSquareBrackets = /\[(.*?)\]/gm;
    const containsCodeBlock = htmlProcessed?.match(regexToGetAllInsideSquareBrackets) || [];
    if (containsCodeBlock.length > 0) {
        htmlProcessed = overrideBlocks(htmlProcessed);
    }

    return (
        <>
            {styleInline && (<style jsx>{`${stringMinify(styleInline)}`}</style>)}
            <div dangerouslySetInnerHTML={{ __html: stringMinify(cloudinaryApply(htmlProcessed)) }} />
        </>
    );
};

export default HtmlComponent;
