import { stringMinify } from 'utils/StringUtils';

const IFrameComponent = ({ iframeUrl }: any) => {
    return (
        <>
            <style jsx>{`
            ${stringMinify(`
                .iframe-container {
                    position: relative;
                    width: 100%;
                    padding-bottom: 0%;
                }    
                .iframe-container iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }    
                .iframe-aspect-ratio {
                    padding-top: 50%;
                }
            `)}
          `}</style>
            <div className="iframe-container embed-responsive embed-responsive-16by9 iframe-aspect-ratio">
                <iframe frameBorder="0" src={iframeUrl} sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts"></iframe>
            </div>
        </>
    );
};

export default IFrameComponent;