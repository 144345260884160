import { useState } from "react";
import { numberWithCommas } from "utils/commonUtils";

const initState = {
    price: 0,
    rate: 0,
    month: 0,
    downPayment: '',
    payment: '',
};

const PaymentCalculatorForm = ({
    defaultRate= 0,
    defaultTerm= 0,
    defaultPrice=0,
}: {
    defaultRate?: number,
    defaultTerm?: number,
    defaultPrice?: number,
}) => {
    const [record, setRecord] = useState({
        ...initState,
        rate: defaultRate,
        month: defaultTerm,
        price: defaultPrice,
    });

    const onChangeRecord = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const { name, value} = e.target;

        setRecord({
            ...record,
            [name]: value,
            payment: '',
        });
    };

    const calculatePayment = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const p = Number(record.price) - Number(record.downPayment);
        const r = Number(record.rate) / 1200;
        const n = Number(record.month);

        const result = Math.floor((r + (r / (Math.pow(1 + r, n) - 1))) * p);

        setRecord(
            {
                ...record,
                payment: String(result),
            }
        );
    };

    return (
        <div className="details-form contact-2">
            <form
                className="gray-form"
                id="paymentcalculatorformid"
                data-parsley-validate="true"
            >
                <h2>Payment Calculator</h2>
                <div className="form-group">
                    <label htmlFor="vdppcprice">Vehicle Price ($)*</label>
                    <input
                        id="vdppcprice"
                        name="price"
                        type="text"
                        className="form-control"
                        placeholder="Price"
                        data-parsley-required="true"
                        aria-required="true"
                        value={record.price}
                        onChange={onChangeRecord}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="vdppcrate">Interest anual rate (%)*</label>
                    <input
                        id="vdppcrate"
                        type="text"
                        className="form-control"
                        placeholder="Rate"
                        data-parsley-required="true"
                        aria-required="true"
                        name="rate"
                        value={record.rate}
                        onChange={onChangeRecord}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="vdppcperiod">Loan Term (Month)*</label>
                    <input
                        id="vdppcperiod"
                        type="text"
                        className="form-control"
                        placeholder="Month"
                        data-parsley-required="true"
                        aria-required="true"
                        name="month"
                        value={record.month}
                        onChange={onChangeRecord}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="vdppcdownpayment">Down Payment *</label>
                    <input
                        id="vdppcdownpayment"
                        type="text"
                        className="form-control"
                        placeholder="Down Payment"
                        data-parsley-required="true"
                        aria-required="true"
                        name="downPayment"
                        value={record.downPayment}
                        onChange={onChangeRecord}
                    />
                </div>
                <div className="form-group">
                    <button id="vpdcalculate" className="button red"
                        onClick={calculatePayment}
                    >
                        {Number(record.payment) > 0 ? 
                            numberWithCommas(Number(record.payment)) + ' Mo**' : "** Estimate Payment" }
                    </button>
                    <span
                        className="red cursor-pointer"
                        onClick={()=> setRecord(initState)}
                    >
            Clear form
                    </span>
                </div>
                <div style={{ fontSize: "11px" }} className="form-group">
                    <span>* Mandatory fields.</span> <br />
                    <span>
            ** Estimated monthly payment based on the term of the loan, interest
            rate, and down payment. See Dealer for details.
                    </span>
                </div>
            </form>
        </div>
    );
};

export default PaymentCalculatorForm;
