const useMailMethods = () => {
    const sendEmail = async ({
        from = "",
        to = "",
        subject = "",
        message = "",
        isHtml = false,
    }: {
    from: string,
    to: string,
    subject: string,
    message: string,
    isHtml: boolean,
  }) => {
        const response = await fetch(`/api/mail?from=${from}&to=${to}&subject=${subject}&message=${message}&isHtml=${isHtml}`
        ).then((response) => response.json());
        return response.data ?? false;
    };

    return { sendEmail };
};

export default useMailMethods;
