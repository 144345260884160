import { renderToString } from 'react-dom/server';

const SocialMediaLink = ({ itemKey, type, settings }: any) => {
    const keyValue = itemKey?.replace(/[\[\]']+/g,'') || '';
    const property = Object.keys(settings).find(item=> item.toLowerCase() === keyValue.toLowerCase()) || 'key';
    const link = settings[property];
    if (!link) return (<span className="ax-empty-tag"></span>);

    return (
        <a target="_blank" aria-label={type} rel="noreferrer" href={link}>
            <i className={`fa fa-${type}`}></i>
        </a>
    );
};

const CreateSocialMediaLink = (itemKey: string, type:string, settings:any): string => {
    return renderToString(<SocialMediaLink itemKey={itemKey} type={type} settings={settings} />);
};

export { 
    SocialMediaLink,
    CreateSocialMediaLink,
};