const CareerForm = () => {
    return (
        <div className="row">
            <div className="col-lg-12 col-sm-6 col-xs-12">
                <form
                    className="gray-form"
                    id="careerform"
                    data-parsley-validate="true"
                    action="#"
                >
                    <div className="form-group">
                        <label htmlFor="career-position">Position *</label>
                        <select
                            className="form-control websitekey ax-select-link"
                            id="career-position"
                            data-keytype="text"
                            data-parsley-required="true"
                            aria-required="true"
                        >
                            <option value="">Select the position</option>
                            {/* @for (int i = 0; i < positions.Count(); i++)
                    {
                        if (i == 0 && positions[0] == "")
                        {
                            <option value="Other">No positions available</option>
                        }
                        else
                        {
                            < option value = "@(positions[i].Trim())" >@(positions[i].Trim()) </ option >
                        }
                    } */}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="career-name">Name *</label>
                        <input
                            type="text"
                            className="form-control"
                            id="career-name"
                            placeholder="your name"
                            data-parsley-required="true"
                            aria-required="true"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="career-email">Email *</label>
                        <input
                            type="email"
                            className="form-control"
                            id="career-email"
                            placeholder="johndoe@email.com"
                            data-parsley-required="true"
                            aria-required="true"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="career-phone">Phone *</label>
                        <input
                            type="tel"
                            className="form-control"
                            id="career-phone"
                            placeholder="(000)000-0000"
                            data-parsley-required="true"
                            aria-required="true"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="career-message">Message *</label>
                        <textarea
                            className="form-control"
                            id="career-message"
                            rows={10}
                            placeholder="write your message here"
                            data-parsley-required="true"
                            aria-required="true"
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <button className="button red" id="submit-contact">
              Send message
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CareerForm;
