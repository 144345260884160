import { InputField } from 'components/Forms/FormField';
import { isValidSchema } from 'utils/SchemaUtils';
import { useState } from 'react';
import ContactSchema from 'components/Forms/Schema/ContactSchema';
import ErrorMessage from 'components/ErrorMessage';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import { phoneNumberWithDash } from 'utils/commonUtils';
import Captcha from 'components/Captcha/Captcha';
import useMailMethods from 'components/Hooks/useMailMethods';

const Loading = dynamic(() => import('components/Loading'));

const initialState = {
    name: '',
    email: '',
    phone: '',
    message: '',
    captchaVerified: false,
};

const ContactForm = ({ settings }: any) => {
    const [record, setRecord] = useState(initialState);
    const [submitError, setSubmitError] = useState('');
    const [isSaving, setSaving] = useState(false);
    const { sendEmail } = useMailMethods();
    const { generalInfo, companyInfo } = settings;

    const onChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if(name === 'phone')
            value = phoneNumberWithDash(value);
        setRecord((item) => ({
            ...item,
            [name]: value,
        }));
    };

    const { isValid } = isValidSchema(ContactSchema, record);

    const onSubmit = async () => {
        if (!isValid) return;
        setSaving(true);
        setSubmitError('');

        try {
            const contactName = record.name;
            const email = record.email ?? null;

            const result = await sendEmail({
                from: companyInfo.companyName, 
                to: generalInfo.contactEmail, 
                subject: `Information from contact page : ${email ? email : contactName}`, 
                isHtml: true,
                message: `<strong>Client name: </strong><span>${contactName}</span><br/><strong>Phone number: </strong><br/> <span>${record.phone}</span>   <br/><strong>Message: </strong><br/> <span>${record.message}</span>`
            });

            setSaving(false);

            if (result) {                
                Router.push("/contact/thankyou");
            } else {
                setSubmitError('error while send email');
            }
        } catch (error: any) {
            setSaving(false);
            setSubmitError(error?.message || error);
        }
    };

    const onValidateCaptcha = async (val: boolean) => {
        setRecord({
            ...record,
            captchaVerified: val,
        });
    };

    return (
        <div
            className="form-horizontal"
            id="contactform"
            data-parsley-validate="true"
        >
            <div className="contact-form">
                <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                        <label htmlFor="user-name">Name *</label>
                        <InputField
                            formSchema={ContactSchema}
                            record={record}
                            onChange={onChange}
                            type="text"
                            placeholder="your name"
                            className="form-control"
                            name="name"
                            disabled={isSaving}
                        />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                        <label htmlFor="fromEmailId">Email *</label>
                        <InputField
                            formSchema={ContactSchema}
                            record={record}
                            onChange={onChange}
                            type="email"
                            placeholder="johndoe@email.com"
                            className="form-control"
                            name="email"
                            disabled={isSaving}                            
                        />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                        <label htmlFor="user-phone">Phone *</label>
                        <InputField
                            formSchema={ContactSchema}
                            record={record}
                            onChange={onChange}
                            type="tel"
                            placeholder="999-999-9999"
                            className="form-control"
                            name="phone"
                            disabled={isSaving}
                            value={record.phone}
                        />
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                        <label htmlFor="user-message">Message *</label>
                        <textarea
                            className="form-control input-message"
                            placeholder="write your message here"
                            rows={7}
                            onChange={onChange}
                            name="message"
                            disabled={isSaving}
                        ></textarea>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                    <Captcha
                        onValidateCaptcha={(val: boolean)=> onValidateCaptcha(val)}
                    />
                </div>
                <div className="col-lg-12 col-md-12">
                    {!isValid && (<div><ErrorMessage message="Please fill all the required fields!." /></div>)}
                    {!record.captchaVerified && (<div><ErrorMessage message="Please solve the captcha." /></div>)}
                    <button disabled={isSaving || !isValid || !record.captchaVerified} onClick={onSubmit} className="button red">
                        {isSaving ? <Loading text='processing...' /> : 'Send your message'}
                    </button>
                    {submitError.length > 0 && (<div><ErrorMessage message={submitError} /></div>)}
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
